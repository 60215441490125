import React, { useState } from "react";
import Shell from "../shell";
import Main from "../main";
import Cart from "../cart";

const data = [
  {
    title: "Cancun",
    description:
      "Viagem para Cancun entre Agosto e Novembro, para duas pessoas com hospedagem.",
    src:
      "https://abrilviagemeturismo.files.wordpress.com/2016/12/cancun1.jpg?quality=70&strip=info&w=680&h=453&crop=1"
  },
  {
    title: "Canadá",
    description:
      "Passagem única para Toronto entre Março e Maio, sem hospedagem.",
    src:
      "http://www.jornalnorthnews.com/arquivos/noticias/674/10ec07248467bfcf1ff05e094da2c959.png"
  }
];

const Nav = props => {
  const [screen, setScreen] = useState({
    show: "main",
    readTerms: false,
    data
  });

  const componentMapper = {
    main: Main,
    cart: Cart
  };

  const Component = componentMapper[screen.show];

  return (
    <Shell
      goToMain={() =>
        setScreen({
          ...screen,
          show: "main"
        })
      }
    >
      <Component setScreen={setScreen} screenProps={screen} />
    </Shell>
  );
};

export default Nav;
