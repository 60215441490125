import React from "react";
import { Col, Row } from "antd";
import "./index.css";
import JustyCard from "./components/Card";

const Main = props => {
  // nav
  const { setScreen, screenProps } = props;

  const { data } = screenProps;
  return (
    <Row type="flex">
      {data.map((item, index) => (
        <Col
          key={index}
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xxl={6}
          style={{ display: "flex" }}
        >
          <JustyCard
            title={item.title}
            description={item.description}
            src={item.src}
            onSelect={() => {
              console.log(screenProps);
              // nav
              setScreen({
                ...screenProps,
                show: "cart",
                title: item.title,
                description: item.description,
                src: item.src
              });
            }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Main;
