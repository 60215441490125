import React from "react";
import { Layout, Menu, Icon } from "antd";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";

const { Header, Footer } = Layout;

const Content = styled.div`
  flex: 1;
  background: #fff;
  padding: 24px;
`;

const Shell = props => {
  const { children } = props;

  // nav
  const { goToMain } = props;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <div
          className="logo"
          onClick={goToMain}
          style={{ cursor: "pointer" }}
        />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item key="2" style={{ float: "right" }}>
            <Icon type="user" />
            Sair
          </Menu.Item>
        </Menu>
      </Header>
      <Layout.Content
        style={{
          padding: "0 4vw",
          marginTop: 64,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Text type="secondary" style={{ paddingTop: 20, paddingBottom: 20 }}>
          Seja bem-vindo, Ítalo.
          <Text type="danger" style={{ float: "right" }}>
            3 dias restantes
          </Text>
        </Text>
        <Content>{children}</Content>
      </Layout.Content>
      <Footer style={{ textAlign: "center" }}>Troca Legal ©2019</Footer>
    </Layout>
  );
};

export default Shell;
