import React from "react";
import { Card, Button, Row, Icon } from "antd";
import Cover from "../../shared/ImageCover";

const JustyCard = props => {
  const { onSelect } = props;
  const { title, description, src } = props;
  return (
    <Row type="flex">
      <Card
        hoverable
        style={{
          margin: "5px",
          flex: "1",
          display: "flex",
          flexDirection: "column"
        }}
        cover={<Cover src={src} />}
        className="justy-main-card"
      >
        <Card.Meta title={title} description={description} />
        <Row
          justify="end"
          type="flex"
          style={{ paddingTop: "25px", justifySelf: "flex-end", flex: "1" }}
          align="bottom"
        >
          <Button type="link"> Condições </Button>
          <Button type="primary" onClick={onSelect}>
            Selecionar
            <Icon type="right" />
          </Button>
        </Row>
      </Card>
    </Row>
  );
};

export default JustyCard;
