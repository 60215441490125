import React from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Row as AntdRow,
  Col,
  Checkbox,
  Icon,
  Button
} from "antd";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import Shell from "../shell";
import Cover from "../shared/ImageCover";

const Img = styled.div`
  background-color: red;
  width: 100%;
  height: 100px;
`;

const Row = {
  main: styled(AntdRow)`
    border: 1px solid #e8e8e8;
    border-right: none;
    border-left: none;
    padding: 5px 0px;
    margin-bottom: 10px;
  `,
  secondary: styled(AntdRow)`
    flex: 1;
  `,
  normal: AntdRow,
  flex: props => <AntdRow type="flex" {...props} />
};

const Cart = props => {
  // nav
  const { setScreen, screenProps } = props;

  const { src, title, description } = screenProps;

  const onCheck = el => {
    setScreen({
      ...screenProps,
      readTerms: el.target.checked
    });
  };

  return (
    <>
      <Row.normal>
        <Col span={24}>
          <Title style={{ color: "rgba(0, 0, 0, 0.73)" }} level={3}>
            Você selecionou
          </Title>
        </Col>
      </Row.normal>
      <Row.main type="flex" gutter={{ xs: 8, sm: 12, md: 16, xl: 24 }}>
        <Col
          xs={10}
          sm={6}
          lg={4}
          style={{ alignItems: "center", display: "flex" }}
        >
          <Cover src={src} />
        </Col>
        <Col xs={14} sm={18} lg={20} style={{ display: "flex" }}>
          <Row.secondary type="flex">
            <Col xs={24} md={20}>
              <Text strong>{title}</Text>
              <br />
              <Text type="secondary">{description}</Text>
              <br />
              <Text type="secondary">Oferecido por: </Text>
              <Text strong type="secondary">
                XYZ Airlines
              </Text>
              <br />
              <Text type="secondary">REF: ABCXYZ22</Text>
            </Col>
            <Col
              xs={24}
              md={4}
              style={{
                alignSelf: "center",
                padding: 6,
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Button
                onClick={() => {
                  setScreen({
                    ...screenProps,
                    show: "main"
                  });
                }}
              >
                Remover
              </Button>
            </Col>
          </Row.secondary>
        </Col>
      </Row.main>
      <Row.flex justify="space-around" style={{ marginTop: 25 }}>
        <Col xs={24} md={18}>
          <Row.flex>
            <Col xs={24} md={20}>
              <Checkbox checked={screenProps.readTerms} onChange={onCheck}>
                Confirmo que li os <a href="#">Termos e Condições</a>
              </Checkbox>
            </Col>

            <Col
              xs={24}
              md={4}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Button type="primary" disabled={!screenProps.readTerms}>
                Finalizar <Icon type="right" />
              </Button>
            </Col>
          </Row.flex>
        </Col>
      </Row.flex>
    </>
  );
};

export default Cart;
